<template>
  <div>
  </div>
</template>
<script>

import users_ws from '../services/users';
import accounts_ws from '../services/account';
import store from '../store'

export default {
  name: 'Home',
  components: {
    
  },
  data () {
    return {

    }
  },
  mounted: async function() {
    let analytics = await accounts_ws.getOnboarding(); 

    if(analytics.type == "success"){
      if(analytics.data === true){
        window.location.href = "/#/welcome"; return;
      }
    }

    let response = await users_ws.getUserPermissions(); 

    if(response.type == "success"){
      store.commit('setPermissions', response.data);

      if(response.data.dashboard === undefined || response.data.dashboard == 1){
        window.location.href = "/#/dashboard"; return;
      }
      else{
        window.location.href = "/#/invoices"; return;
      }
    }
  },
  methods: {
    
  }
}
</script>
